.ra-trustpilot {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:has(iframe) {
    display: block;
  }
}
